import { Stack } from "@mui/material";
import React, {useRef, useEffect } from "react";
import { DatePicker, Labeling } from "views/atoms";
import {
  CheckBox,
  CheckBoxGroup,
  Group,
  InputField,
  PostalCodeField,
  PrefecturesSelector,
} from "views/molecules";
import { getUniqueInformationLabel } from "api/graphql/queries";
import { useQuery } from "@apollo/client";

/**
 * 検索条件を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element} JSX.Element
 */
export const Searcher = (props) => {
  const uniqueInfoInputRef = useRef(null);

  useEffect(() => {
    if (props.uniqueInfo.error) {
      uniqueInfoInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.uniqueInfo.error]);

  const handleChangeConstruction = (name) => (value) => {
    props.onChange({
      ...props.value,
      construction: {
        ...props?.value?.construction,
        [name]: value,
      },
    });
  };

  const handleChangeConstructionImplementation = (name) => (date) => {
    props.onChange({
      ...props.value,
      construction: {
        ...props?.value?.construction,
        implementation: {
          ...props?.value?.construction?.implementation,
          [name]: date,
        },
      },
    });
  };

  const handleChangeConstructionAddress = (name) => (value) => {
    props.onChange({
      ...props.value,
      construction: {
        ...props?.value?.construction,
        address: {
          ...props?.value?.construction.address,
          [name]: value,
        },
      },
    });
  };

  const handleChangeApplyCompleted = (name) => (date) => {
    props.onChange({
      ...props.value,
      apply: {
        ...props?.value?.apply,
        completed: {
          ...props?.value?.apply?.completed,
          [name]: date,
        },
      },
    });
  };

  const handleChangeApply = (name) => (value) => {
    props.onChange({
      ...props.value,
      apply: {
        ...props?.value?.apply,
        [name]: value,
      },
    });
  };

  const handleChange = (name) => (value) => {
    props.onChange({
      ...props.value,
      [name]: value,
    });
  };

  const uniqueInformationLabel = useQuery(getUniqueInformationLabel);
  const getUniqueInformationLabelText = (value) => {
    if (!value) {
      return "";
    }

    let result = [];
    result.push(value?.getUniqueInformationLabel?.labelName1 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName2 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName3 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName4 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName5 ?? "");
    result = result.filter((item) => !!item && item !== "").join();
    return result === "" ? "" : `${result}`;
  };

  return (
    <>
      <Group title="工事" bold={true} elevation={1}>
        <InputField
          label="名称"
          name="name"
          value={props.value?.construction?.name ?? ""}
          onChange={(e) => handleChangeConstruction("name")(e.target.value)}
          error={Boolean(props.value?.construction?.name?.message)}
          helperText={props.errors?.construction?.name?.message}
        />
        <Labeling title="実施期間">
          <Stack direction={"row"} spacing={2}>
            <DatePicker
              name="implementation.start"
              value={props.value?.construction?.implementation?.start}
              onChange={(value) =>
                handleChangeConstructionImplementation("start")(value)
              }
              error={!!props.errors?.construction?.implementation?.start}
              helperText={props.errors?.construction?.implementation?.start}
            />
            <DatePicker
              name="implementation.end"
              value={props.value?.construction?.implementation?.end}
              onChange={(value) =>
                handleChangeConstructionImplementation("end")(value)
              }
              error={!!props.errors?.construction?.implementation?.end}
              helperText={props.errors?.construction?.implementation?.end}
            />
          </Stack>
        </Labeling>
        <PostalCodeField
          label="郵便番号"
          value={props.value?.construction?.postal}
          onChange={(value) => handleChangeConstruction("postal")(value)}
          error={Boolean(props.errors?.construction?.postal)}
          helperText={
            props.errors?.construction?.postal?.message ||
            props.errors?.construction?.postal?.front?.message ||
            props.errors?.construction?.postal?.back?.message
          }
        />
        <PrefecturesSelector
          label="都道府県"
          name="prefecture"
          value={props.value?.construction?.address?.prefecture ?? ""}
          onChange={(e) =>
            handleChangeConstructionAddress("prefecture")(e.target.value)
          }
          error={Boolean(
            props.errors?.construction?.address?.prefecture?.message
          )}
          helperText={props.errors?.construction?.address?.prefecture?.message}
        />
        <InputField
          label="市区町村"
          name="city"
          value={props.value?.construction?.address?.city ?? ""}
          onChange={(e) =>
            handleChangeConstructionAddress("city")(e.target.value)
          }
          error={Boolean(props.errors?.construction?.address?.city?.message)}
          helperText={props.errors?.construction?.address?.city?.message}
        />
        <InputField
          label="町名番地"
          name="town"
          value={props.value?.construction?.address?.town ?? ""}
          onChange={(e) =>
            handleChangeConstructionAddress("town")(e.target.value)
          }
          error={Boolean(props.errors?.construction?.address?.town?.message)}
          helperText={props.errors?.construction?.address?.town?.message}
        />
        <InputField
          label="町名番地以降"
          name="other"
          value={props.value?.construction?.address?.other ?? ""}
          onChange={(e) =>
            handleChangeConstructionAddress("other")(e.target.value)
          }
          error={Boolean(props.errors?.construction?.address?.other?.message)}
          helperText={props.errors?.construction?.address?.other?.message}
        />
        {getUniqueInformationLabelText(uniqueInformationLabel?.data) !== "" && (
          <InputField
            label={`${getUniqueInformationLabelText(
              uniqueInformationLabel?.data
            )}`}
            name="uniqueInformation"
            onChange={(e) => {
              const input = e.target.value;
              const length = input.split("\n").length;
              if (length <= 100) {
                handleChangeConstruction("uniqueInformation")(input);
                props.setUniqueInfo({
                  error: null,
                  length: length,
                });
              } else {
                props.setUniqueInfo({
                  error: "100行を超えています。",
                  length: length,
                });
              }
            }}
            inputRef={uniqueInfoInputRef}
            value={props.value?.construction?.uniqueInformation ?? ""}
            sx={{
              "& .MuiFormHelperText-root": {
                textAlign: props.uniqueInfo.error ? "left" : "right",
              },
            }}
            helperText={props.uniqueInfo.error ?? `${props.uniqueInfo.length}/100 行`}
            multiline
            maxRows={15}
            error={!!props.uniqueInfo.error}
          />
        )}
      </Group>
      <Group title="申請" bold={true} elevation={1}>
        <Labeling title="申請期間">
          <Stack direction={"row"} spacing={2}>
            <DatePicker
              name="completed.start"
              value={props.value?.apply?.completed?.start}
              onChange={(value) => handleChangeApplyCompleted("start")(value)}
              error={!!props.errors?.apply?.completed?.start}
              helperText={props.errors?.apply?.completed?.start}
            />
            <DatePicker
              name="completed.end"
              value={props.value?.apply?.completed?.end}
              onChange={(value) => handleChangeApplyCompleted("end")(value)}
              error={!!props.errors?.apply?.completed?.end}
              helperText={props.errors?.apply?.completed?.end}
            />
          </Stack>
        </Labeling>
        <InputField
          name="number"
          label={"番号"}
          value={props.value?.apply?.number ?? ""}
          onChange={(e) => handleChangeApply("number")(e.target.value)}
          inputProps={{ maxLength: 10 }}
          error={props.errors?.apply?.date?.number}
        />
        <CheckBoxGroup
          name="classification"
          label="区分"
          value={props.value?.apply?.classification}
          onChange={(e) => handleChangeApply("classification")(e.value)}
          row={true}
          error={Boolean(props.errors?.apply?.classification?.message)}
          helperText={props.errors?.apply?.classification?.message}
          options={[
            {
              label: "労働安全衛生法",
              value: "1",
            },
            {
              label: "大気汚染防止法",
              value: "2",
            },
          ]}
        />
      </Group>
      <Group title="状況" bold={true} elevation={1}>
        <Group title="CSV出力状態" bold={false} elevation={1}>
          <Stack direction={"row"}>
            <CheckBox
              name="isNotOutputedCSV"
              label="未出力"
              value={props.value?.isNotOutputedCSV ?? null}
              onChange={(e) =>
                handleChange("isNotOutputedCSV")(e.target.checked)
              }
            />
            <CheckBox
              name="isOutputedCSV"
              label="出力済"
              value={props.value?.isOutputedCSV}
              onChange={(e) => handleChange("isOutputedCSV")(e.target.checked)}
            />
          </Stack>
        </Group>
        <Group title="事前調査" bold={false} elevation={1}>
          <CheckBoxGroup
            name="preResearchStatus"
            label=""
            value={props.value?.preResearchStatus}
            onChange={(e) => handleChange("preResearchStatus")(e.value)}
            row={true}
            error={Boolean(props.errors?.preResearchStatus?.message)}
            helperText={props.errors?.preResearchStatus?.message}
            options={[
              {
                label: "作成中",
                value: "1",
                disabled: true,
              },
              {
                label: "調査完了",
                value: "2",
              },
              {
                label: "調査不要(解体が無い軽微な工事)",
                value: "3",
              },
            ]}
          />
        </Group>
        <Group title="申請先" bold={false} elevation={1}>
          <Stack direction={"row"}>
            <CheckBox
              name="unapply"
              label="未設定"
              value={props.value?.unapply ?? null}
              onChange={(e) => handleChange("unapply")(e.target.checked)}
            />
            <CheckBox
              name="applied"
              label="設定済"
              value={props.value?.applied ?? null}
              onChange={(e) => handleChange("applied")(e.target.checked)}
            />
          </Stack>
        </Group>
        <Group title="報告" bold={false} elevation={1}>
          <CheckBoxGroup
            name="reportStatus"
            label=""
            value={props.value?.reportStatus}
            onChange={(e) => handleChange("reportStatus")(e.value)}
            row={true}
            error={Boolean(props.errors?.reportStatus?.message)}
            helperText={props.errors?.reportStatus?.message}
            options={[
              {
                label: "未報告",
                value: "1",
              },
              {
                label: "報告済",
                value: "2",
              },
              {
                label: "要再報告",
                value: "3",
              },
              {
                label: "報告不要",
                value: "4",
              },
            ]}
          />
        </Group>
      </Group>
    </>
  );
};
