import { useState, useEffect } from "react";
import { useTable } from "./Table/useTable";
import { AsbestosReportType } from "utils/Constants";

/**
 * 報告のコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const [apply, setApply] = useState(null);
  const [importer, setImporter] = useState(false);
  const [selected, setSelected] = useState([]);
  const [report, setReport] = useState(null);
  const tableParams = useTable();

  useEffect(() => {
    tableParams.reload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickApplyItem = (data) => {
    setApply(data?.value);
  };

  const handleClickReportItem = (data) => {
    setReport({
      id: data.value.id,
      name: data.value.name,
      number: data.value?.surveyApplicationNo?.toString(),
      date: data.value?.asbestosReportDate,
      status: data?.value?.asbestosReportStatusId,
    });
  };

  const handleCloseReportForm = () => {
    setReport(false);
  };

  const handleCheckedChangeItem = (data) => {
    setSelected(data);
  };

  const handleCloseApplyForm = () => {
    setApply(null);
  };

  const handleApplyNotificationChange = () => {
    setApply(null);
    tableParams.reload();
  };

  const handleImportNotificationChange = () => {
    setImporter(false);
    tableParams.reload();
  };

  const handleReportNotificationChange = (result) => {
    setReport(null);

    if (selected) {
      const index = selected.findIndex((i) => i.id === result.id);
      if (index !== -1) {
        let reselectedItem = { ...selected[index] };
        reselectedItem.asbestosReportStatusId = result.asbestosReportStatusId;
        reselectedItem.asbestosReportStatus =
          result.asbestosReportStatusId === AsbestosReportType.Unreported.value
            ? AsbestosReportType.Unreported.label
            : result.asbestosReportStatusId ===
              AsbestosReportType.Reported.value
            ? AsbestosReportType.Reported.label
            : result.asbestosReportStatusId ===
              AsbestosReportType.ReReportingRequired.value
            ? AsbestosReportType.ReReportingRequired.label
            : result.asbestosReportStatusId ===
              AsbestosReportType.NoReportRequired.value
            ? AsbestosReportType.NoReportRequired.label
            : "";
        reselectedItem.surveyApplicationNo = result.surveyApplicationNo;

        let reselected = [...selected];
        reselected.splice(index, 1, reselectedItem);
        setSelected(reselected);
      }
    }

    tableParams.reload();
  };

  const handleImport = () => {
    setImporter(true);
  };

  const handleCloseImport = () => {
    setImporter(false);
  };

  return render({
    ...props,
    selected: selected,
    onClickApplyItem: handleClickApplyItem,
    onClickReportItem: handleClickReportItem,
    onCheckedChangeItem: handleCheckedChangeItem,
    onCloseApplyForm: handleCloseApplyForm,
    onCloseReportForm: handleCloseReportForm,
    apply: apply,
    importer: importer,
    onCloseImport: handleCloseImport,
    onApplyNotificationChange: handleApplyNotificationChange,
    onImportNotificationChange: handleImportNotificationChange,
    onReportNotificationChange: handleReportNotificationChange,
    onImport: handleImport,
    report: report,
    tableParams: tableParams,
  });
};
