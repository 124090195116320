import { useLazyQuery } from "@apollo/client";
import { searchConstructionOrders } from "api/graphql/queries";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const defaultSearchValues = {
  constructionName: null,
  uniqueInformation: null,
  constructionStartDate: null,
  constructionEndDate: null,
  workPostNoFront: null,
  workPostNoBack: null,
  workAddressPrefecture: null,
  workAddressCity: null,
  workAddressTown: null,
  workAddressOther: null,
  ordersStatus: ["1", "2"],
  surveyStatus: ["1"],
  reportStatus: null,
  searchMode: null,
};

// Regular expression for matching one or more whitespace characters. The regular expression \s+ matches one or more whitespace characters, which includes spaces, tabs, and newline characters.
let whitespaceRegex = /\s+/;

export const useContructionList = () => {
  const location = useLocation();
  const pageSize = 100;
  const [page, setPage] = useState(location?.state?.page ?? 0);
  const [filter, setFilter] = useState(defaultSearchValues);
  const [functions, options] = useLazyQuery(searchConstructionOrders);
  const [startCursor, setStartCursor] = useState(location?.state?.startCursor);
  const [endCursor, setEndCursor] = useState(location?.state?.endCursor);

  useEffect(() => {
    if (location?.search === "") {
      const result = {
        ...defaultSearchValues,
      };

      functions({
        variables: {
          first: pageSize,
          last: undefined,
          before: undefined,
          after: undefined,
          filter: {
            constructionName: result?.constructionName,
            constructionStartDate: result?.constructionStartDate,
            constructionEndDate: result?.constructionEndDate,
            workPostNoFront: result?.workPostNoFront,
            workPostNoBack: result?.workPostNoBack,
            workAddressPrefecture: result?.workAddressPrefecture,
            workAddressCity: result?.workAddressCity,
            workAddressTown: result?.workAddressTown,
            workAddressOther: result?.workAddressOther,
            uniqueInformation:
              result?.uniqueInformation?.split(whitespaceRegex),
            isOrderStatusNegotiation: result?.ordersStatus?.includes("1"),
            isOrderStatusReceived: result?.ordersStatus?.includes("2"),
            isOrderStatusLost: result?.ordersStatus?.includes("3"),
            isResearchStatusUnfinished: result?.surveyStatus?.includes("1"),
            isResearchStatusCreated: result?.surveyStatus?.includes("2"),
            isResearchStatusUnnecessary: result?.surveyStatus?.includes("3"),
            isReportStatusNone: result?.reportStatus?.includes("1"),
            isReportStatusFinished: result?.reportStatus?.includes("2"),
            isReportStatusNeededAgain: result?.reportStatus?.includes("3"),
            isReportStatusUnnecessary: result?.reportStatus?.includes("4"),
          },
        },
      }).then(() => {
        setPage(0);
        setFilter(result);
      });
    } else {
      const queries = queryString.parse(location.search);

      functions({
        variables: {
          first: pageSize,
          last: undefined,
          before: startCursor,
          after: endCursor,
          filter: {
            constructionName: queries?.constructionName,
            constructionStartDate: queries?.constructionStartDate,
            constructionEndDate: queries?.constructionEndDate,
            workPostNoFront: queries?.workPostNoFront,
            workPostNoBack: queries?.workPostNoBack,
            workAddressPrefecture: queries?.workAddressPrefecture,
            workAddressCity: queries?.workAddressCity,
            workAddressTown: queries?.workAddressTown,
            workAddressOther: queries?.workAddressOther,
            uniqueInformation:
              queries?.uniqueInformation?.split(whitespaceRegex),
            isOrderStatusNegotiation: queries?.ordersStatus?.includes("1"),
            isOrderStatusReceived: queries?.ordersStatus?.includes("2"),
            isOrderStatusLost: queries?.ordersStatus?.includes("3"),
            isResearchStatusUnfinished: queries?.surveyStatus?.includes("1"),
            isResearchStatusCreated: queries?.surveyStatus?.includes("2"),
            isResearchStatusUnnecessary: queries?.surveyStatus?.includes("3"),
            isReportStatusNone: queries?.reportStatus?.includes("1"),
            isReportStatusFinished: queries?.reportStatus?.includes("2"),
            isReportStatusNeededAgain: queries?.reportStatus?.includes("3"),
            isReportStatusUnnecessary: queries?.reportStatus?.includes("4"),
            searchMode: Number(queries?.searchMode) ?? undefined,
          },
        },
      }).then(() => {
        setPage(location?.state?.page ?? 0);
        setFilter(queries);
      });
    }
  }, [location.search]);

  const handleNext = () => {
    setStartCursor(null);
    setEndCursor(options?.data?.searchConstructionOrders?.pageInfo?.endCursor);
    functions({
      variables: {
        first: pageSize,
        last: undefined,
        before: undefined,
        after: options?.data?.searchConstructionOrders?.pageInfo?.endCursor,
        filter: {
          constructionName: filter?.constructionName,
          constructionStartDate: filter?.constructionStartDate,
          constructionEndDate: filter?.constructionEndDate,
          workPostNoFront: filter?.workPostNoFront,
          workPostNoBack: filter?.workPostNoBack,
          workAddressPrefecture: filter?.workAddressPrefecture,
          workAddressCity: filter?.workAddressCity,
          workAddressTown: filter?.workAddressTown,
          workAddressOther: filter?.workAddressOther,
          uniqueInformation: filter?.uniqueInformation?.split(whitespaceRegex),
          isOrderStatusNegotiation: filter?.ordersStatus?.includes("1"),
          isOrderStatusReceived: filter?.ordersStatus?.includes("2"),
          isOrderStatusLost: filter?.ordersStatus?.includes("3"),
          isResearchStatusUnfinished: filter?.surveyStatus?.includes("1"),
          isResearchStatusCreated: filter?.surveyStatus?.includes("2"),
          isResearchStatusUnnecessary: filter?.surveyStatus?.includes("3"),
          isReportStatusNone: filter?.reportStatus?.includes("1"),
          isReportStatusFinished: filter?.reportStatus?.includes("2"),
          isReportStatusNeededAgain: filter?.reportStatus?.includes("3"),
          isReportStatusUnnecessary: filter?.reportStatus?.includes("4"),
          searchMode: Number(filter?.searchMode) ?? undefined,
        },
      },
    }).then((res) => {
      setPage((prevState) => prevState + 1);
    });
  };

  const handlePrevious = () => {
    setStartCursor(
      options?.data?.searchConstructionOrders?.pageInfo?.startCursor
    );
    setEndCursor(null);
    functions({
      variables: {
        first: undefined,
        last: pageSize,
        before: options?.data?.searchConstructionOrders?.pageInfo?.startCursor,
        after: undefined,
        filter: {
          constructionName: filter?.constructionName,
          constructionStartDate: filter?.constructionStartDate,
          constructionEndDate: filter?.constructionEndDate,
          workPostNoFront: filter?.workPostNoFront,
          workPostNoBack: filter?.workPostNoBack,
          workAddressPrefecture: filter?.workAddressPrefecture,
          workAddressCity: filter?.workAddressCity,
          workAddressTown: filter?.workAddressTown,
          workAddressOther: filter?.workAddressOther,
          uniqueInformation: filter?.uniqueInformation?.split(whitespaceRegex),
          isOrderStatusNegotiation: filter?.ordersStatus?.includes("1"),
          isOrderStatusReceived: filter?.ordersStatus?.includes("2"),
          isOrderStatusLost: filter?.ordersStatus?.includes("3"),
          isResearchStatusUnfinished: filter?.surveyStatus?.includes("1"),
          isResearchStatusCreated: filter?.surveyStatus?.includes("2"),
          isResearchStatusUnnecessary: filter?.surveyStatus?.includes("3"),
          isReportStatusNone: filter?.reportStatus?.includes("1"),
          isReportStatusFinished: filter?.reportStatus?.includes("2"),
          isReportStatusNeededAgain: filter?.reportStatus?.includes("3"),
          isReportStatusUnnecessary: filter?.reportStatus?.includes("4"),
          searchMode: Number(filter?.searchMode) ?? undefined,
        },
      },
    }).then((res) => {
      setPage((prevState) => prevState - 1);
    });
  };

  const handleSearch = (newFilter) => {
    const queries = queryString.parse(newFilter);
    functions({
      variables: {
        first: pageSize,
        last: undefined,
        before: undefined,
        after: undefined,
        filter: {
          constructionName: queries?.constructionName,
          constructionStartDate: queries?.constructionStartDate,
          constructionEndDate: queries?.constructionEndDate,
          workPostNoFront: queries?.workPostNoFront,
          workPostNoBack: queries?.workPostNoBack,
          workAddressPrefecture: queries?.workAddressPrefecture,
          workAddressCity: queries?.workAddressCity,
          workAddressTown: queries?.workAddressTown,
          workAddressOther: queries?.workAddressOther,
          uniqueInformation: queries?.uniqueInformation?.split(whitespaceRegex),
          isOrderStatusNegotiation: queries?.ordersStatus?.includes("1"),
          isOrderStatusReceived: queries?.ordersStatus?.includes("2"),
          isOrderStatusLost: queries?.ordersStatus?.includes("3"),
          isResearchStatusUnfinished: queries?.surveyStatus?.includes("1"),
          isResearchStatusCreated: queries?.surveyStatus?.includes("2"),
          isResearchStatusUnnecessary: queries?.surveyStatus?.includes("3"),
          isReportStatusNone: queries?.reportStatus?.includes("1"),
          isReportStatusFinished: queries?.reportStatus?.includes("2"),
          isReportStatusNeededAgain: queries?.reportStatus?.includes("3"),
          isReportStatusUnnecessary: queries?.reportStatus?.includes("4"),
          searchMode: Number(queries?.searchMode) ?? undefined,
        },
      },
    }).then((res) => {
      setStartCursor(undefined);
      setEndCursor(undefined);
      setPage(0);
      setFilter(queries);
    });
  };

  const refresh = () => {
    options.refetch();
  };

  return {
    pageSize: pageSize,
    page: page,
    startCursor: startCursor,
    endCursor: endCursor,
    handleNext: handleNext,
    handlePrevious: handlePrevious,
    filter: filter,
    filterQueryString: queryString.stringify({
      constructionName: filter.constructionName
        ? filter.constructionName
        : null,
      uniqueInformation: filter?.uniqueInformation?.split(whitespaceRegex),
      constructionStartDate: filter?.constructionStartDate,
      constructionEndDate: filter?.constructionEndDate,
      workPostNoFront: filter?.workPostNoFront,
      workPostNoBack: filter?.workPostNoBack,
      workAddressPrefecture: filter?.workAddressPrefecture,
      workAddressCity: filter?.workAddressCity,
      workAddressTown: filter?.workAddressTown,
      workAddressOther: filter?.workAddressOther,
      ordersStatus: filter?.ordersStatus,
      surveyStatus: filter?.surveyStatus,
      reportStatus: filter?.reportStatus,
      searchMode: Number(filter?.searchMode) ?? undefined,
    }),
    handleSearch: handleSearch,
    items: options?.data?.searchConstructionOrders?.items,
    hasNextPage:
      options?.data?.searchConstructionOrders?.pageInfo?.hasNextPage ?? false,
    hasPreviousPage:
      options.data?.searchConstructionOrders?.pageInfo?.hasPreviousPage ??
      false,
    totalCount: options?.data?.searchConstructionOrders?.totalCount ?? 0,
    loading: options.loading,
    error: options.error,
    refresh: refresh,
  };
};
