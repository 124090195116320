import DateFnsUtils from "@date-io/date-fns";
import { InsertInvitationOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import jaLocale from "date-fns/locale/ja";
import React from "react";

/**
 * 日付の選択の表示を行うプレゼンテーションコンポーネントです
 * @param {string} value 日付
 * @param {func} onChange 日付を更新するイベントです。
 * @param {string} label 日付テキストのラベル
 */
export const Presententer = (props) => {
  return (
    <LocalizationProvider
      utils={DateFnsUtils}
      locale={jaLocale}
      dateAdapter={AdapterDateFns}
    >
      <DatePicker
        autoOk
        disabled={props.disabled}
        inputVariant="outlined"
        variant="inline"
        inputFormat={props.format}
        views={
          props.format === "yyyy/MM"
            ? ["year", "month"]
            : ["year", "month", "day"]
        }
        fullWidth
        slotProps={{
          textField: { size: "small" },
        }}
        value={props?.date ?? ""}
        InputAdornmentProps={{ position: "end" }}
        keyboardIcon={<InsertInvitationOutlined fontSize="small" />}
        onChange={(date) => {
          props.datechange(date);
        }}
        label={props.label}
        PopoverProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        mask={props.format === "yyyy/MM" ? "____/__" : "____/__/__"}
        renderInput={(parmas) => {
          return (
            <TextField
              {...parmas}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              helperText={props?.error?.message}
              error={Boolean(props.error)}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
