import DateFnsUtils from "@date-io/date-fns";
import { InsertInvitationOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as Picker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import jaLocale from "date-fns/locale/ja";
import React from "react";

/**
 * 日付を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.disabled 無効設定
 * @param {string} props.inputFormat 入力フォーマット (yyyy/MM/dd or yyyy/MM)
 * @param {Date} props.value 日付
 * @param {string} props.label ラベル
 * @param {boolean} props.helperText 補助的テキスト
 * @param {boolean} props.fullWidth 最大幅に広げるかどうか
 * @fires DatePicker#onChange 変更時
 * @returns {JSX.Element}
 */
export const DatePicker = (props) => {
  const {
    disabled,
    inputFormat = "yyyy/MM/dd",
    value,
    onChange,
    label,
    error,
    helperText,
    fullWidth = false,
  } = props;

  return (
    <LocalizationProvider
      utils={DateFnsUtils}
      locale={jaLocale}
      dateAdapter={AdapterDateFns}
    >
      <Picker
        autoOk
        disabled={disabled}
        inputVariant="outlined"
        variant="inline"
        format={inputFormat}
        views={
          inputFormat === "yyyy/MM/dd"
            ? ["year", "month", "day"]
            : ["year", "month"]
        }
        fullWidth
        slotProps={{
          textField: { size: "small" },
        }}
        value={value ? new Date(value) : null}
        InputAdornmentProps={{ position: "end" }}
        keyboardIcon={<InsertInvitationOutlined fontSize="small" />}
        onChange={onChange}
        label={label}
        PopoverProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        mask={inputFormat === "yyyy/MM/dd" ? "____/__/__" : "____/__"}
        renderInput={(parmas) => {
          return (
            <TextField
              {...parmas}
              size="small"
              fullWidth={fullWidth}
              InputLabelProps={{ shrink: true }}
              helperText={helperText}
              error={!!error}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
