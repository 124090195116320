import { useLazyQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { confirmCsvFile } from "api/graphql/queries";
import React, { useEffect, useState } from "react";
import { convertFileToFormValue } from "utils/Functions";
import { FileUploader } from "views/molecules/FileUploader";
import { ApplyList } from "./ApplyList";

/**
 * ファイルを選択させるコンポーネントです。
 * @param {object} value CSVデータ
 * @fires FileSelect#onNext 次へ
 * @returns {JSX.Element}
 */
export const FileSelectStep = ({ onNext = () => {} }) => {
  const [errors, setErrors] = useState(null);
  const [csv, setCSV] = useState(null);
  const [confirmCsvFileFunctions, confirmCsvFileOptions] = useLazyQuery(
    confirmCsvFile,
    {
      onCompleted: (data) => {
        if (data?.confirmCsvFile?.applyCsvDataError?.length > 0) {
          setCSV(null);
          setErrors({
            data: data.confirmCsvFile.applyCsvDataError,
            message: "合致しないものが存在しました。",
          });
        } else if (data?.confirmCsvFile?.applyCsvDataNormal?.length > 0) {
          onNext(csv, data.confirmCsvFile.applyCsvDataNormal);
          setCSV(null);
        } else {
          // todo: 通常のデータもエラーのデータも無い場合は形式的なエラーで表示していいのか？
          setCSV(null);
          setErrors({
            data: [],
            message: "データを読みこることができませんでした。",
          });
        }
      },
    }
  );

  useEffect(() => {
    if (csv?.base64) {
      confirmCsvFileFunctions({
        variables: {
          fileName: csv?.name,
          csvFileData: csv?.base64,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csv]);

  const handleChange = async (file) => {
    setCSV(file?.[0]);
    const v = await convertFileToFormValue(file?.[0]);
    setCSV(v);
  };

  return (
    <Box paddingTop={2} display="flex" flexDirection={"column"} width={"100%"}>
      <FileUploader
        accept={"text/csv, application/vnd.ms-excel"}
        buttonTitle="CSVファイルを選択"
        onChange={handleChange}
        maxFiles={1}
        disabled={confirmCsvFileOptions.loading}
      />
      {errors && (
        <Box marginTop={5}>
          <Typography color="error">{errors?.message}</Typography>
          <Box
            sx={{
              overflow: "auto",
            }}
          >
            {errors?.data?.length > 0 && <ApplyList rows={errors.data} />}
          </Box>
        </Box>
      )}
    </Box>
  );
};
