import { DateTime } from "luxon";
import QueryString from "query-string";
import { useEffect, useState, useRef } from "react";
import { getUniqueInformationLabel } from "api/graphql/queries";
import { useQuery } from "@apollo/client";

const defaulValues = {
  constructionName: null,
  uniqueInformation: null,
  constructionStartDate: null,
  constructionEndDate: null,
  workPostNoFront: null,
  workPostNoBack: null,
  workAddressPrefecture: null,
  workAddressCity: null,
  workAddressTown: null,
  workAddressOther: null,
  ordersStatus: ["1", "2"],
  surveyStatus: ["1"],
  reportStatus: null,
  searchMode: null,
};

const convertDate = (value) => {
  if (!value) {
    return null;
  }

  let result = DateTime.fromJSDate(value);
  if (result.isValid) {
    return result;
  }

  result = DateTime.fromFormat(value, "yyyy/MM/dd");
  if (result.isValid) {
    return result;
  }

  result = DateTime.fromFormat(value, "yyyy/MM/dd");
  if (result.isValid) {
    return result;
  }

  return value;
};

/**
 * 工事情報検索の表示を行うコンテナコンポーネントです。
 * @param {object} props
 * @callback render
 * @returns
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => {},
  open = false,
  onClose = () => console.log("full screen onClose"),
  ...props
}) => {
  const [search, setSearch] = useState(defaulValues);
  const [uniqueInfo, setUniqueInfo] = useState({
    error: null,
    length: 0,
  });
  const uniqueInformationLabel = useQuery(getUniqueInformationLabel);
  const uniqueInfoInputRef = useRef(null);
  useEffect(() => {
    setSearch({
      ...defaulValues,
      ...value,
    });
  }, [value]);

  useEffect(() => {
    if (uniqueInfo.error) {
      uniqueInfoInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [uniqueInfo.error]);

  const handleSearch = () => {
    onSubmit( 
      QueryString.stringify({
        constructionName: search.constructionName
          ? search.constructionName
          : null,
        uniqueInformation: search.uniqueInformation
          ? search.uniqueInformation
          : null,
        constructionStartDate: convertDate(search?.constructionStartDate),
        constructionEndDate: convertDate(search?.constructionEndDate),
        workPostNoFront: search?.workPostNoFront,
        workPostNoBack: search?.workPostNoBack,
        workAddressPrefecture: search?.workAddressPrefecture,
        workAddressCity: search?.workAddressCity,
        workAddressTown: search?.workAddressTown,
        workAddressOther: search?.workAddressOther,
        ordersStatus: search?.ordersStatus,
        surveyStatus: search?.surveyStatus,
        reportStatus: search?.reportStatus,
        searchMode: value?.searchMode ? Number(value?.searchMode) : undefined,
      })
    );

    onClose();
  };

  const handleChange = (name) => (value) => {
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getUniqueInformationLabelText = (value) => {
    if (!value) {
      return "";
    }

    let result = [];
    result.push(value?.getUniqueInformationLabel?.labelName1 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName2 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName3 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName4 ?? "");
    result.push(value?.getUniqueInformationLabel?.labelName5 ?? "");
    result = result.filter((item) => !!item && item !== "").join();
    return result === "" ? "" : `${result}`;
  };

  return render({
    ...props,
    value: search,
    onSearch: handleSearch,
    open: open,
    onCancel: onClose,
    onChange: handleChange,
    uniqueInformationLabel: getUniqueInformationLabelText(
      uniqueInformationLabel?.data
    ),
    setUniqueInfo,
    uniqueInfo,
    uniqueInfoInputRef
  });
};
