import queryString from "query-string";
import { useLocation } from "react-router";

/**
 * ARS-検索の結果ページのコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const location = useLocation();
  const variables = { ...queryString.parse(location.search) };

  return render({
    variables: {
      mode: variables?.mode ? Number(variables.mode) : undefined,
      keyword: variables.keyword,
      year: variables?.year ? Number(variables.year) : undefined,
      productFieldId: variables.productFieldId,
      materialId: variables.materialId,
      makerId: variables.makerId,
      usefulnessId: variables.usefulnessId,
    },
    ...props,
  });
};
