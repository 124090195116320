import { showError, showSuccess } from "ducks/NotificationMessages";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { scrollTop } from "views/organisms/AuthenticatedTemplate";

/**
 * 工事登録ページのコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  loading,
  error,
  createFunction,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const [value, setValue] = useState(null);
  const [toggle, setToggle] = useState(true);
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    setValue(data);
    setToggle(false);
    scrollTop();
  };

  const handleConfirm = () => {
    formRef.current.submit();
  };

  const handleBack = () => {
    setToggle(true);
  };

  const getDate = (value, date) => {
    if (!value) {
      return null;
    }

    if (value === "") {
      return null;
    }

    let result = DateTime.fromJSDate(value);
    if (result.isValid) {
      if (date) {
        return result.toISODate();
      } else {
        return result.toISOTime();
      }
    }

    result = DateTime.fromFormat(value, "yyyy/MM/dd");

    if (result.isValid) {
      if (date) {
        return result.toISODate();
      } else {
        return result.toISOTime();
      }
    }

    result = DateTime.fromFormat(value, "yyyy/MM");

    if (result.isValid) {
      if (date) {
        return result.toISODate();
      } else {
        return result.toISOTime();
      }
    }

    return value;
  };

  const convertCheckbox = (value) => {
    if (typeof value === "boolean") {
      return value ? "1" : "0";
    }

    return value;
  };

  const handleRegist = () => {
    const {
      buildDate,
      constructionStartDate,
      constructionEndDate,
      asbestosWorkStartDate,
      preliminarySurveyEndDate,
      constructionOrderStatus,
      buildingFireproof,
      buildingStructure,
      buildingFloorSpace,
      constructionFloorSpace,
      laborInsuranceNoExist,
      buildDateExist,
      groundFloors,
      basementFloors,
      amountBillion,
      amountTenThousand,
      freeEntryField,
      primeContractor,
      orderer,
      work,
      ...other
    } = value;

    createFunction({
      variables: {
        ...other,
        constructionOrderStatus: constructionOrderStatus
          ? Number(constructionOrderStatus)
          : undefined,
        buildDate: getDate(buildDate, true),
        constructionStartDate: getDate(constructionStartDate, true),
        constructionEndDate: getDate(constructionEndDate, true),
        asbestosWorkStartDate: getDate(asbestosWorkStartDate, true),
        preliminarySurveyEndDate: getDate(preliminarySurveyEndDate, false),
        buildingFireproof: buildingFireproof?.id,
        buildingStructure: buildingStructure?.id,
        laborInsuranceNoExist: convertCheckbox(laborInsuranceNoExist),
        buildDateExist: convertCheckbox(buildDateExist),
        groundFloors: groundFloors ? Number(groundFloors) : undefined,
        buildingFloorSpace: buildingFloorSpace
          ? Number(buildingFloorSpace)
          : undefined,
        constructionFloorSpace: constructionFloorSpace
          ? Number(constructionFloorSpace)
          : undefined,
        basementFloors: basementFloors ? Number(basementFloors) : undefined,
        amountBillion: amountBillion ? Number(amountBillion) : undefined,
        amountTenThousand: amountTenThousand
          ? Number(amountTenThousand)
          : undefined,
        freeEntryField: freeEntryField,
        primeContractorPostNoFront: primeContractor?.PostNoFront,
        primeContractorPostNoBack: primeContractor?.PostNoBack,
        primeContractorAddressPrefecture: primeContractor?.AddressPrefecture,
        primeContractorAddressCity: primeContractor?.AddressCity,
        primeContractorAddressTown: primeContractor?.AddressTown,
        primeContractorAddressOther: primeContractor?.AddressOther,
        ordererPostNoFront: orderer?.PostNoFront,
        ordererPostNoBack: orderer?.PostNoBack,
        ordererAddressPrefecture: orderer?.AddressPrefecture,
        ordererAddressCity: orderer?.AddressCity,
        ordererAddressTown: orderer?.AddressTown,
        ordererAddressOther: orderer?.AddressOther,
        workPostNoFront: work?.PostNoFront,
        workPostNoBack: work?.PostNoBack,
        workAddressPrefecture: work?.AddressPrefecture,
        workAddressCity: work?.AddressCity,
        workAddressTown: work?.AddressTown,
        workAddressOther: work?.AddressOther,
        otherWorkInput: {
          isReactionTank: value.isReactionTank,
          isHeatingFurnace: value.isHeatingFurnace,
          isBoilerAndPressureVessel: value.isBoilerAndPressureVessel,
          isPlumbingEquipment: value.isPlumbingEquipment,
          isIncinerator: value.isIncinerator,
          isChimney: value.isChimney,
          isStorageEquipment: value.isStorageEquipment,
          isPowerGenerationEquipment: value.isPowerGenerationEquipment,
          isSubstationEquipment: value.isSubstationEquipment,
          isPowerDistributionEquipment: value.isPowerDistributionEquipment,
          isPowerTransmissionEquipment: value.isPowerTransmissionEquipment,
          isTunnelCeilingBoard: value.isTunnelCeilingBoard,
          isPlatformHouse: value.isPlatformHouse,
          isSoundInsulationWall: value.isSoundInsulationWall,
          isLightweightFillProtectionPanel:
            value.isLightweightFillProtectionPanel,
          isRailwayStationsWall: value.isRailwayStationsWall,
          isShip: value.isShip,
        },
      },
    })
      .then((res) => {
        if (res.data.createConstruction.result === "OK") {
          dispatch(
            showSuccess({
              message: "登録しました。",
            })
          );
          navigate(
            `/constructions${
              location?.state?.preSearchWord
                ? `?${location?.state?.preSearchWord}`
                : ``
            }`,
            {
              replace: true,
              state: { ...location?.state },
            }
          );
        } else {
          dispatch(
            showError({
              message: "登録に失敗しました。",
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          showError({
            message: "登録に失敗しました。",
          })
        );
      });
  };

  const handleBackList = () => {
    navigate(
      `/constructions${
        location?.state?.preSearchWord
          ? `?${location?.state?.preSearchWord}`
          : ``
      }`,
      {
        replace: true,
        state: { ...location?.state },
      }
    );
  };

  return render({
    ...props,
    formRef: formRef,
    value: value,
    toggle: toggle,
    onSubmit: handleSubmit,
    onConfirm: handleConfirm,
    onRegist: handleRegist,
    onBack: handleBack,
    onBackList: handleBackList,
  });
};
