import { useEffect } from "react";
import { useLocation } from "react-router";
import { useContructionList } from "views/organisms/Constructions/ConstructionList/useContructionList";

/**
 * 工事一覧を表示するのコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const listParams = useContructionList();
  const location = useLocation();

  useEffect(() => {
    listParams.refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return render({
    listParams: listParams,
    ...props,
  });
};
