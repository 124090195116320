import { useQuery } from "@apollo/client";
import { searchConstructionOrders, searchReports } from "api/graphql/queries";
import { useEffect, useState } from "react";

const defaultAlertValues = {
  incompleteConstruction: false,
  unapplied: false,
  needToWithdraw: false,
};

/**
 * ARS-ホームページのコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [alert, setAlert] = useState(defaultAlertValues);
  const searchConstructionOrdersParams = useQuery(searchConstructionOrders, {
    variables: {
      filter: {
        searchMode: 1,
      },
    },
    onCompleted: (data) => {
      setAlert((prevState) => ({
        ...prevState,
        incompleteConstruction:
          (data?.searchConstructionOrders?.items?.length ?? 0) > 0,
      }));
    },
  });

  const searchReportsParams = useQuery(searchReports, {
    variables: {
      filter: {
        searchMode: 1,
      },
    },
    onCompleted: (data) => {
      setAlert((prevState) => ({
        ...prevState,
        unapplied: (data?.searchReports?.items?.length ?? 0) > 0,
      }));
    },
  });

  const searchReportsParams2 = useQuery(searchReports, {
    variables: {
      filter: {
        searchMode: 2,
      },
    },
    onCompleted: (data) => {
      setAlert((prevState) => ({
        ...prevState,
        needToWithdraw: (data?.searchReports?.items?.length ?? 0) > 0,
      }));
    },
  });

  useEffect(() => {
    searchConstructionOrdersParams.refetch();
    searchReportsParams.refetch();
    searchReportsParams2.refetch();
  }, [
    searchConstructionOrdersParams,
    searchReportsParams,
    searchReportsParams2,
  ]);

  return render({ alert: alert, ...props });
};
