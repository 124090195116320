import { useMutation } from "@apollo/client";
import { updateSurveyApplicationNo } from "api/graphql/mutations";
import { showError, showSuccess } from "ducks/NotificationMessages";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AsbestosReportType } from "utils/Constants";

const defaultValue = {
  number: "",
  date: null,
  status: AsbestosReportType.Unreported.value,
};

const convertDate = (value) => {
  if (!value) {
    return null;
  }

  if (value === "") {
    return null;
  }

  let result = DateTime.fromJSDate(value);
  if (result.isValid) {
    return result.toISODate();
  }

  result = DateTime.fromFormat(value, "yyyy/MM/dd");
  if (result.isValid) {
    return result.toISODate();
  }

  result = DateTime.fromFormat(value, "yyyy-MM-dd");
  if (result.isValid) {
    return result.toISODate();
  }

  return null;
};

/**
 * 報告フォーム表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  open,
  onClose,
  value,
  onNotificationChange = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updateSurveyApplicationNoFunction, updateSurveyApplicationNoOptions] =
    useMutation(updateSurveyApplicationNo);
  const {
    reset,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      ...defaultValue,
      ...value,
    },
  });

  useEffect(() => {
    reset({
      ...defaultValue,
      ...value,
    });
    setLoading(false);
  }, [value]);

  const handleChange = (name) => (data) => {
    setValue(name, data);
  };

  const handleRegist = async () => {
    const result = await trigger();
    const entered = watch();

    if (result) {
      setLoading(true);
      updateSurveyApplicationNoFunction({
        variables: {
          id: value.id,
          surveyApplicationNo: entered?.number?.toString(),
          asbestosReportDate: convertDate(entered?.date),
          asbestosReportStatus: entered.status
            ? Number(entered.status)
            : undefined,
        },
      })
        .then((res) => {
          dispatch(
            showSuccess({
              message: "登録しました。",
            })
          );
          onNotificationChange({
            id: value.id,
            surveyApplicationNo: entered?.number?.toString(),
            asbestosReportDate: convertDate(entered?.date),
            asbestosReportStatus: entered.status
              ? Number(entered.status)
              : undefined,
          });
        })
        .catch(() => {
          dispatch(
            showError({
              message: "登録に失敗しました。",
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return render({
    ...props,
    open: open,
    onClose: onClose,
    value: watch(),
    onChange: handleChange,
    onRegist: handleRegist,
    errors: errors,
    loading: loading || updateSurveyApplicationNoOptions.loading,
  });
};
