import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
import { DateTime } from "luxon";
import { OptionYears } from "utils/Constants";
import { AsbestosResearchStatusType } from "utils/Constants";

const invertDateTime = (value) => {
  if (!value) {
    return null;
  }

  if (value === "") {
    return null;
  }

  let result = DateTime.fromJSDate(value);
  if (result.isValid) {
    return result.toISODate();
  }

  result = DateTime.fromFormat(value, "yyyy/MM/dd");
  if (result.isValid) {
    return result.toISODate();
  }

  result = DateTime.fromFormat(value, "yyyy/MM");
  if (result.isValid) {
    return result.toISODate();
  }

  return value;
};

/**
 * 事前調査フォームを表示するコンポーネントです。
 * @param {object} value 工事情報
 */
export const Form = containerPresententer(Container, Presententer);

export const defaultValue = {
  unnecessary: false,
  asbestosWorkStartDate: null,
  preliminarySurveyEndDate: null,
  analysisSurveyLocation: null,
  primeContractor: {
    preSurveyorName: null,
    preSurveyorTrainingInstitutionName: null,
    regulationClass: null,
    analysisName: null,
    affiliationName: null,
    analysisTrainingInstitutionName: null,
    asbestosWorkChiefName: null,
  },
  details: null,
  spraying: null,
  heatRetention: null,
  chimney: null,
  forRoof: null,
  fireproof: null,
  finishingCoating: null,
  slateWavePlate: null,
  slateBoard: null,
  makeupSlateForRoof: null,
  silicicAcidCalciumBoardFirstType: null,
  extrusionFormationCementPlate: null,
  pulpCementPlate: null,
  vinylFloorTile: null,
  steamingSystemSiding: null,
  plasterboard: null,
  lockWoolSoundAbsorbingCeilingBoard: null,
  otherMaterial: null,
};

/**
 * api value to form value
 */
export const convert = (value) => {
  if (!value) {
    return null;
  }

  const {
    id,
    asbestosResearchStatusId,
    asbestosResearchStatus,
    asbestosWorkStartDate,
    preliminarySurveyEndDate,
    analysisSurveyLocation,
    preliminarySurveyorName,
    preSurveyorTrainingInstitutionName,
    analyticalSurveyorName,
    anaSurveyorAffiliationName,
    anaSurveyorTrainingInstitutionName,
    asbestosWorkChiefName,
    trainingRulesClassification,
    asbestosResearchRemarkses,
    asbestosResearchDetails,
  } = value;

  const convertMaterial = (item) => {
    return {
      id: item.asbestosTargetMaterial?.id,
      existsAsbestos: item?.asbestosContainsResult?.id,
      basis: [
        item.judgedNoneVisually === true && "visually",
        item.judgedNoneDesignDocuments === true && "designDocuments",
        item.judgedNoneManufacturerCertification === true && "proof",
        item.judgedNoneAnalysis === true && "analysis",
        item.judgedNoneMadeDate === true && "date",
      ].filter((item) => item),
      type: item?.asbestosWorkKind?.id,
      existsDisconnect: item.cuttingExist === "01" ? "on" : "off",
      measure: [
        item.workStepNegativePressureIsolation === true &&
          "negativePressureIsolation",
        item.workStepIsolation === true && "isolation",
        item.workStepWetting === true && "wetting",
        item.workStepUseProtectiveEquipment === true && "toolUse",
      ].filter((item) => item),
    };
  };

  return {
    id: id,
    unnecessary:
      asbestosResearchStatusId ===
      AsbestosResearchStatusType.NoInvestigationRequired.value,
    status: asbestosResearchStatus,
    asbestosWorkStartDate: asbestosWorkStartDate,
    preliminarySurveyEndDate: preliminarySurveyEndDate,
    analysisSurveyLocation: analysisSurveyLocation,
    primeContractor: {
      preSurveyorName: preliminarySurveyorName,
      preSurveyorTrainingInstitutionName: preSurveyorTrainingInstitutionName,
      analysisName: analyticalSurveyorName,
      affiliationName: anaSurveyorAffiliationName,
      analysisTrainingInstitutionName: anaSurveyorTrainingInstitutionName,
      asbestosWorkChiefName: asbestosWorkChiefName,
      regulationClass: trainingRulesClassification,
    },
    details: asbestosResearchRemarkses?.map((item) => ({
      content: item.remarks,
      files: item.asbestosResearchRelatedFiles.map((file) => ({
        name: file.name,
        base64: file.fileImage,
      })),
      investigate: {
        conditions: {
          mode: item?.researchRemarksSearchCondition?.searchMode,
          data: {
            keyword: item?.researchRemarksSearchCondition?.freeWord,
            productField: item?.researchRemarksSearchCondition?.productField,
            maker: item?.researchRemarksSearchCondition?.maker,
            material: item?.researchRemarksSearchCondition?.material,
            usefulness: item?.researchRemarksSearchCondition?.usefulness,
            year: OptionYears.find(
              (i) =>
                i.id ===
                item?.researchRemarksSearchCondition?.modelYear?.toString()
            ),
          },
        },
        results: item?.researchRemarksSearchItems.map(
          (item) => item.searchResult
        ),
        judgements: item?.researchRemarksApplicableItems.map(
          (item) => item.searchResult
        ),
      },
    })),
    spraying: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "01")
      ?.map(convertMaterial)?.[0],
    heatRetention: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "02")
      ?.map(convertMaterial)?.[0],
    chimney: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "03")
      ?.map(convertMaterial)?.[0],
    forRoof: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "04")
      ?.map(convertMaterial)?.[0],
    fireproof: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "05")
      ?.map(convertMaterial)?.[0],
    finishingCoating: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "06")
      ?.map(convertMaterial)?.[0],
    slateWavePlate: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "07")
      ?.map(convertMaterial)?.[0],
    slateBoard: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "08")
      ?.map(convertMaterial)?.[0],
    makeupSlateForRoof: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "09")
      ?.map(convertMaterial)?.[0],
    silicicAcidCalciumBoardFirstType: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "10")
      ?.map(convertMaterial)?.[0],
    extrusionFormationCementPlate: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "11")
      ?.map(convertMaterial)?.[0],
    pulpCementPlate: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "12")
      ?.map(convertMaterial)?.[0],
    vinylFloorTile: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "13")
      ?.map(convertMaterial)?.[0],
    steamingSystemSiding: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "14")
      ?.map(convertMaterial)?.[0],
    plasterboard: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "15")
      ?.map(convertMaterial)?.[0],
    lockWoolSoundAbsorbingCeilingBoard: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "16")
      ?.map(convertMaterial)?.[0],
    otherMaterial: asbestosResearchDetails
      ?.filter((item) => item.asbestosTargetMaterial?.id === "17")
      ?.map(convertMaterial)?.[0],
  };
};

/**
 * form value to api value
 */
export const invert = (value, status) => {
  const {
    id,
    unnecessary,
    asbestosWorkStartDate,
    preliminarySurveyEndDate,
    analysisSurveyLocation,
    primeContractor,
    details,
    spraying,
    heatRetention,
    chimney,
    forRoof,
    fireproof,
    finishingCoating,
    slateWavePlate,
    slateBoard,
    makeupSlateForRoof,
    silicicAcidCalciumBoardFirstType,
    extrusionFormationCementPlate,
    pulpCementPlate,
    vinylFloorTile,
    steamingSystemSiding,
    plasterboard,
    lockWoolSoundAbsorbingCeilingBoard,
    otherMaterial,
  } = value;

  const materials = [
    spraying,
    heatRetention,
    chimney,
    forRoof,
    fireproof,
    finishingCoating,
    slateWavePlate,
    slateBoard,
    makeupSlateForRoof,
    silicicAcidCalciumBoardFirstType,
    extrusionFormationCementPlate,
    pulpCementPlate,
    vinylFloorTile,
    steamingSystemSiding,
    plasterboard,
    lockWoolSoundAbsorbingCeilingBoard,
    otherMaterial,
  ];

  return {
    id: id,
    asbestosResearchStatus: unnecessary ? "3" : status,
    asbestosWorkStartDate: invertDateTime(asbestosWorkStartDate),
    preliminarySurveyEndDate: invertDateTime(preliminarySurveyEndDate),
    analysisSurveyLocation: analysisSurveyLocation,
    preliminarySurveyorName: primeContractor?.preSurveyorName,
    preSurveyorTrainingInstitutionName:
      primeContractor?.preSurveyorTrainingInstitutionName,
    analyticalSurveyorName: primeContractor?.analysisName,
    anaSurveyorAffiliationName: primeContractor?.affiliationName,
    anaSurveyorTrainingInstitutionName:
      primeContractor?.analysisTrainingInstitutionName,
    asbestosWorkChiefName: primeContractor?.asbestosWorkChiefName,
    trainingRulesClassification: primeContractor?.regulationClass?.id,
    asbestosResearchDetailInputs:
      materials
        .filter((material) => !!material)
        .map((material) => ({
          asbestosTargetMaterial: material?.id,
          asbestosContainsResult: material.existsAsbestos,
          judgedNoneVisually: material?.basis?.includes?.("visually") ?? false,
          judgedNoneDesignDocuments:
            material?.basis?.includes?.("designDocuments") ?? false,
          judgedNoneManufacturerCertification:
            material?.basis?.includes?.("proof") ?? false,
          judgedNoneAnalysis: material?.basis?.includes?.("analysis") ?? false,
          judgedNoneMadeDate: material?.basis?.includes?.("date") ?? false,
          asbestosWorkKind: material.type,
          cuttingExist: material.existsDisconnect === "on" ? "01" : "02",
          workStepNegativePressureIsolation:
            material.measure?.includes?.("negativePressureIsolation") ?? false,
          workStepIsolation: material.measure?.includes?.("isolation") ?? false,
          workStepWetting: material.measure?.includes?.("wetting") ?? false,
          workStepUseProtectiveEquipment:
            material.measure?.includes?.("toolUse") ?? false,
        })) ?? null,
    asbestosResearchRemarksInputs:
      details?.map((detail) => {
        return {
          remarks: detail?.content,
          asbestosResearchFilesInputs: detail?.files?.map((file) => {
            return {
              fileName: file?.name,
              fileImage: file?.base64,
            };
          }),
          researchRemarksSearchConditionInput: {
            searchMode: detail?.investigate?.conditions?.mode,
            freeWord: detail?.investigate?.conditions?.data?.keyword,
            modelYear: detail?.investigate?.conditions?.data?.year?.id,
            productFieldId:
              detail?.investigate?.conditions?.data?.productField?.id,
            makerId: detail?.investigate?.conditions?.data?.maker?.id,
            materialId: detail?.investigate?.conditions?.data?.material?.id,
            usefulnessId: detail?.investigate?.conditions?.data?.usefulness?.id,
          },
          researchRemarksSearchItemInputs: detail?.investigate?.results?.map(
            (item) => ({ id: item.id })
          ),
          researchRemarksApplicableItemInputs:
            detail?.investigate?.judgements?.map((item) => ({ id: item.id })),
        };
      }) ?? null,
  };
};

export const notSelectedExistsAsbestos = (value) => {
  return !value?.existsAsbestos;
};

export const warningNotSelectedNegativePressureIsolation = (value) => {
  return (
    !value?.measure ||
    value?.measure?.includes("negativePressureIsolation") === false
  );
};

export const warningNotSelectedWarmAndTool = (value) => {
  return (
    !value?.measure ||
    value?.measure?.includes("wetting") === false ||
    value?.measure?.includes("toolUse") === false
  );
};
